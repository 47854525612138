import { FC } from 'react';

import Text, { TextSize } from 'bloko/blocks/text';

import HumanDate, { ShowYear } from 'src/components/HumanDate';

import styles from './article-list-item.less';

type ArticlePublicationInfoProps = {
    publicationDate: string;
    showCurrentYear?: boolean;
};

const ArticlePublicationInfo: FC<ArticlePublicationInfoProps> = ({ publicationDate, showCurrentYear }) => {
    return (
        <div className={styles.captionInfo}>
            <Text size={TextSize.Small}>
                <HumanDate date={publicationDate} showYear={showCurrentYear ? ShowYear.Always : ShowYear.NotCurrent} />
            </Text>
        </div>
    );
};

export default ArticlePublicationInfo;
