import { FC } from 'react';

import Column from 'bloko/blocks/column';

import BlogCategoryLayout from 'src/pages/Blog/components/BlogCategoryLayout';

import SpecialProjectList from 'src/pages/Blog/components/SpecialProjects/SpecialProjectList';
import SpecialProjectsGrid from 'src/pages/Blog/components/SpecialProjects/SpecialProjectsGrid';

const BlogCategoryPage: FC = () => (
    <BlogCategoryLayout showTopicsFilter>
        <Column xs="0" s="8" m="9" l="13">
            <SpecialProjectsGrid />
        </Column>
        <Column xs="4" s="0" m="0" l="0">
            <SpecialProjectList />
        </Column>
    </BlogCategoryLayout>
);

export default BlogCategoryPage;
