import { FC } from 'react';

import Column from 'bloko/blocks/column';

import { useSelector } from 'src/hooks/useSelector';

import BlogArticleGrid from 'src/pages/Blog/components/BlogArticleGrid';
import BlogArticleList from 'src/pages/Blog/components/BlogArticleList';
import BlogCategoryLayout from 'src/pages/Blog/components/BlogCategoryLayout';

const BlogCategoryPage: FC = () => {
    const { isDenseCategory, rubrics, rubric } = useSelector(({ blogPage }) => blogPage);
    const isRubricWithoutTopics = !rubrics.find(({ code }) => code === rubric)?.topics?.length;
    const showLargeArticleGrid = isRubricWithoutTopics && isDenseCategory;
    const showSmallArticleGrid = !isRubricWithoutTopics && isDenseCategory;

    if (showLargeArticleGrid) {
        return (
            <BlogCategoryLayout showTopicsFilter={false}>
                <Column xs="0" s="8" m="12" l="16">
                    <BlogArticleGrid large />
                </Column>
                <Column xs="4" s="0" m="0" l="0">
                    <BlogArticleList noPublicationInfo />
                </Column>
            </BlogCategoryLayout>
        );
    }

    if (showSmallArticleGrid) {
        return (
            <BlogCategoryLayout showTopicsFilter={true}>
                <Column xs="0" s="8" m="9" l="13">
                    <BlogArticleGrid />
                </Column>
                <Column xs="4" s="0" m="0" l="0">
                    <BlogArticleList noPublicationInfo />
                </Column>
            </BlogCategoryLayout>
        );
    }

    return (
        <BlogCategoryLayout showTopicsFilter={true}>
            <Column xs="4" s="8" m="8" l="12">
                <BlogArticleList />
            </Column>
            <Column xs="0" s="0" m="1" l="1" />
        </BlogCategoryLayout>
    );
};

export default BlogCategoryPage;
