import { memo } from 'react';
import classNames from 'classnames';

import Link from '@hh.ru/redux-spa-middleware/lib/Link';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ArticleRubric, ArticleRubrics, ArticleTopic } from 'src/models/articlePage/types';

import styles from './tag.less';

interface ArticleTagProps {
    topicCode: ArticleTopic;
    hhtmFromLabel?: string;
    isLarge?: boolean;
}

const RubricColors: Record<ArticleRubric, string | null> = {
    [ArticleRubrics.Applicants]: styles.tagRed,
    [ArticleRubrics.Employers]: styles.tagBlue,
    [ArticleRubrics.Students]: styles.tagGreen,
    [ArticleRubrics.LabourMarket]: styles.tagYellow,
    [ArticleRubrics.SiteNews]: styles.tagGrey,
    [ArticleRubrics.Insider]: styles.tagViolet,
    [ArticleRubrics.OwnBusiness]: styles.tagOrange,
    [ArticleRubrics.Specs]: null,
    [ArticleRubrics.Events]: null,
};

const DEFAULT_RUBRIC_COLOR = styles.tagGrey;

const ArticleTag: TranslatedComponent<ArticleTagProps> = ({ topicCode, hhtmFromLabel, isLarge = false }) => {
    const { rubrics } = useSelector(({ blogPage }) => blogPage);
    const rubric = rubrics.find((rubric) => rubric.topics.find((topic) => topic.code === topicCode));
    const topicName = rubric?.topics.find((topic) => topic.code === topicCode)?.name;

    if (!rubric || !topicName) {
        return null;
    }

    return (
        <BlokoLink
            disableVisited
            kind={LinkKind.Tertiary}
            Element={Link}
            to={`/articles/${rubric.code}/${topicCode}`}
            additionalQueryParams={{
                hhtmFromLabel,
            }}
        >
            <div
                className={classNames(styles.tag, RubricColors[rubric.code] || DEFAULT_RUBRIC_COLOR, {
                    [styles.tagLarge]: isLarge,
                })}
            >
                {isLarge ? <Text size={TextSize.Large}>{topicName}</Text> : topicName}
            </div>
        </BlokoLink>
    );
};

export default translation(memo(ArticleTag));
