import { LayoutCode } from 'src/models/blogPage';

const categoryCodes = {
    [LayoutCode.ItProjects]: {
        rubric: 'guide-to-companies',
        topic: 'it-projects',
    },
    [LayoutCode.Insider]: {
        rubric: 'guide-to-companies',
        topic: 'insider',
    },
    [LayoutCode.Events]: {
        rubric: 'events',
    },
};

export default categoryCodes;
