import { FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import { BlogProject } from 'src/models/blogPage';

import styles from 'src/components/ArticleItem/article-item-compact.less';

interface BlogProjectItemProps {
    item: BlogProject;
}

const SpecialProjectsCompactItem: FC<BlogProjectItemProps> = ({ item }) => (
    <div className={styles.compact}>
        <BlokoLink Element={Link} to={item.url} kind={LinkKind.Tertiary} title={item.title} forceHhtmForExternalLink>
            <div className={styles.compactPreviewContainer}>
                {item.image && <img src={item.image} alt={item.title} className={styles.compactPreview} />}
            </div>
            <VSpacing base={3} />
            <Text>{item.title}</Text>
        </BlokoLink>
    </div>
);

export default SpecialProjectsCompactItem;
