import { FC } from 'react';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import BlogCategoryLayout from 'src/pages/Blog/components/BlogCategoryLayout';
import BlogDivider from 'src/pages/Blog/components/BlogDivider';

import BlogPressReleaseGrid from 'src/pages/Blog/components/PressRelease/BlogPressReleaseGrid';

type Props = {
    showTopicsFilter: boolean;
};

const BlogPressReleasePage: FC<Props> = ({ showTopicsFilter }) => {
    return (
        <BlogCategoryLayout showTopicsFilter={showTopicsFilter}>
            <Column xs="4" s="8" m={showTopicsFilter ? '8' : '12'} l={showTopicsFilter ? '12' : '16'}>
                <BlogDivider />
                <VSpacing base={8} />
                <BlogPressReleaseGrid />
            </Column>
        </BlogCategoryLayout>
    );
};

export default BlogPressReleasePage;
