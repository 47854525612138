import { H3Section } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ArticleTag from 'src/components/ArticleItem/Tags/Tag/Tag';
import translation from 'src/components/translation';
import { ArticleTopic } from 'src/models/articlePage/types';

import styles from './blog-tag-cloud.less';

const TrlKeys = {
    title: 'blog.filters.categories',
};

type Props = {
    topics: ArticleTopic[];
    hhtmFromLabel?: string;
};

const BlogTopicsTagCloud: TranslatedComponent<Props> = ({ trls, topics, hhtmFromLabel }) => {
    if (!topics.length) {
        return null;
    }

    return (
        <div>
            <H3Section>{trls[TrlKeys.title]}</H3Section>
            <VSpacing base={6} />
            <div className={styles.tagCloud}>
                {topics.map((topicCode) => (
                    <ArticleTag topicCode={topicCode} key={topicCode} hhtmFromLabel={hhtmFromLabel} isLarge />
                ))}
            </div>
        </div>
    );
};

export default translation(BlogTopicsTagCloud);
