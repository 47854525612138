import { FC, PropsWithChildren } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import Column from 'bloko/blocks/column';
import { H2 } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';

import { BlogProject } from 'src/models/blogPage';

import styles from 'src/components/ArticleItem/article-list-item.less';

interface BlogProjectItemProps {
    item: BlogProject;
}

const ArticleListItemLarge: FC<BlogProjectItemProps & PropsWithChildren> = ({ item }) => (
    <div className={styles.large}>
        <div className={styles.content}>
            <div className={styles.imageWrapper}>
                <Column xs="4" s="3" m="3" l="4">
                    {item.image && (
                        <BlokoLink forceHhtmForExternalLink Element={Link} to={item.url} kind={LinkKind.Tertiary}>
                            <div className={styles.previewContainer}>
                                <img src={item.image} alt={item.title} className={styles.preview} />
                            </div>
                        </BlokoLink>
                    )}
                </Column>
            </div>
            <div className={styles.captionWrapper}>
                <VSpacing base={0} xs={7} />
                <div className={styles.caption}>
                    <BlokoLink forceHhtmForExternalLink Element={Link} to={item.url} kind={LinkKind.Tertiary}>
                        <H2>{item.title}</H2>
                    </BlokoLink>
                </div>
            </div>
        </div>
    </div>
);

export default ArticleListItemLarge;
