import { FC } from 'react';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import BlogArticleList from 'src/pages/Blog/components/BlogArticleList';
import BlogCategoryLayout from 'src/pages/Blog/components/BlogCategoryLayout';
import BlogEventsCalendarFilters from 'src/pages/Blog/components/BlogEventsCalendarFilters';

type Props = {
    showTopicsFilter: boolean;
};

const BlogEventsCalendarPage: FC<Props> = ({ showTopicsFilter }) => {
    return (
        <BlogCategoryLayout showTopicsFilter={showTopicsFilter}>
            <Column xs="4" s="8" m={showTopicsFilter ? '8' : '12'} l={showTopicsFilter ? '12' : '16'}>
                <BlogEventsCalendarFilters />
                <VSpacing base={8} />
                <BlogArticleList isEventsList />
            </Column>
            {showTopicsFilter && <Column xs="0" s="0" m="1" l="1" />}
        </BlogCategoryLayout>
    );
};

export default BlogEventsCalendarPage;
