import { Fragment, ReactElement, FC } from 'react';

import Column from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import { useSelector } from 'src/hooks/useSelector';
import { BlogArticleListLayout } from 'src/models/blogPage';

import BlogArticleList from 'src/pages/Blog/components/BlogArticleList';
import BlogArticlesSlider from 'src/pages/Blog/components/BlogArticlesSlider';
import BlogDivider from 'src/pages/Blog/components/BlogDivider';
import BlogEventsSection from 'src/pages/Blog/components/BlogEventsSection';
import BlogITProjects from 'src/pages/Blog/components/BlogITProjects';
import BlogInsider from 'src/pages/Blog/components/BlogInsider';
import BlogPromoList from 'src/pages/Blog/components/BlogPromoList';
import BlogHeader from 'src/pages/Blog/components/Header';

const items: Record<string, ReactElement> = {
    header: <BlogHeader />,
    slider: <BlogArticlesSlider />,
    main: <BlogArticleList withReadMoreLink />,
    insider: <BlogInsider />,
    events: <BlogEventsSection />,
    'it-projects': <BlogITProjects />,
    promo: <BlogPromoList />,
    divider: <BlogDivider />,
};

const defaultSizes = {
    xs: '4',
    s: '8',
    m: '12',
    l: '16',
};

const BlogIndexPage: FC = () => {
    const { layout } = useSelector(({ blogPage }) => blogPage);

    if (!layout.length) {
        return null;
    }

    const renderElement = (item: BlogArticleListLayout, index: number) => {
        if (!items[item.name]) {
            return null;
        }

        const hasSpacing = item.name !== 'divider';

        return (
            <Fragment key={`${index}-${item.name}`}>
                <Column key={`${item.name}-${index}`} {...(item.layout || (defaultSizes as Record<string, unknown>))}>
                    {hasSpacing && <VSpacing base={7} xs={index === 0 ? 0 : 4} />}
                    {items[item.name]}
                    {hasSpacing && <VSpacing base={7} xs={4} />}
                </Column>
            </Fragment>
        );
    };

    return <>{layout.map(renderElement)}</>;
};

export default BlogIndexPage;
