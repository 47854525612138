import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { BlogArticle, LayoutCode } from 'src/models/blogPage';
import categoryCodes from 'src/pages/Blog/categoryCodes';

import BlogRubricArticleList from 'src/pages/Blog/components/BlogRubricArticleList';

const TrlKeys = {
    title: 'articles.topics.it-projects',
    more: 'blog.articles.projects.more',
};

const layoutCode = LayoutCode.ItProjects;

const BlogITProjects: TranslatedComponent = ({ trls }) => {
    const { articles, layout, data } = useSelector(({ blogPage }) => blogPage);
    const articleListLayout = layout?.find(({ name }) => name === layoutCode);
    const items =
        (data as Record<string, { items: BlogArticle[] }>)?.[layoutCode]?.items || articles?.[layoutCode] || [];

    if (!items.length) {
        return null;
    }

    return (
        <BlogRubricArticleList
            list={items}
            articleListLayout={articleListLayout}
            title={trls[TrlKeys.title]}
            more={trls[TrlKeys.more]}
            {...categoryCodes[layoutCode]}
        />
    );
};

export default translation(BlogITProjects);
