import { FC } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import { H3Section } from 'bloko/blocks/header';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

import { BlogArticle } from 'src/models/blogPage';

import { useGetArticleHref, getArticleImage } from 'src/components/ArticleItem/utils/get-article-properties';

import styles from './article-list-item.less';

type Props = {
    item: BlogArticle;
};

const ArticleListItemSpecial: FC<Props> = ({ item }) => {
    const imageUrl = getArticleImage(item, 'cover');
    const articleHref = useGetArticleHref(item);

    return (
        <BlokoLink Element={Link} to={articleHref} kind={LinkKind.Inversed}>
            <div className={styles.special} style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : {}}>
                <div className={styles.overlay} />
                <div className={styles.caption}>
                    <H3Section Element="h3">{item.title}</H3Section>
                </div>
            </div>
        </BlokoLink>
    );
};

export default ArticleListItemSpecial;
