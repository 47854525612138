import { FC, PropsWithChildren } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

type Props = {
    isActive: boolean;
    href: string;
};

const BlogSubMenuLink: FC<Props & PropsWithChildren> = ({ children, isActive, href }) => {
    if (isActive) {
        return <>{children}</>;
    }
    return (
        <BlokoLink Element={Link} to={href} kind={LinkKind.Tertiary}>
            {children}
        </BlokoLink>
    );
};

export default BlogSubMenuLink;
