import { ForwardedRef, forwardRef, ForwardRefRenderFunction, useState } from 'react';

import BlokoButton, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { FormError } from 'bloko/blocks/form';
import HSpacing from 'bloko/blocks/hSpacing';
import InputText from 'bloko/blocks/inputText';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Form from 'src/components/Form';
import translation from 'src/components/translation';

import styles from 'src/pages/Blog/components/Header/blog-header.less';

const TrlKeys = {
    button: 'blog.search.button',
    placeholder: 'blog.search.placeholder',
    validationMessage: 'blog.search.validationMessage',
};

type SearchFormProps = {
    fwdRef?: ForwardedRef<HTMLInputElement>;
};

const SearchFormRaw: TranslatedComponent<SearchFormProps> = ({ trls, fwdRef }) => {
    const [value, setValue] = useState('');
    const [showValidation, setShowValidation] = useState(false);
    const isSubmitBlocked = showValidation && value === '';
    return (
        <Form
            method="GET"
            action="/articles/search"
            onSubmit={(e) => {
                if (value === '') {
                    e.preventDefault();
                    setShowValidation(true);
                }
            }}
        >
            <div className={styles.searchContainer}>
                <InputText
                    placeholder={trls[TrlKeys.placeholder]}
                    ref={fwdRef}
                    name="query"
                    value={value}
                    onChange={setValue}
                />
                <HSpacing base={2} />
                <BlokoButton type={ButtonType.Submit} kind={ButtonKind.Primary} disabled={isSubmitBlocked}>
                    {trls[TrlKeys.button]}
                </BlokoButton>
            </div>
            <FormError show={isSubmitBlocked}>{trls[TrlKeys.validationMessage]}</FormError>
        </Form>
    );
};

const Translated = translation(SearchFormRaw);
const SearchForm: ForwardRefRenderFunction<HTMLInputElement> = (props, ref) => <Translated fwdRef={ref} />;

export default forwardRef(SearchForm);
