import { RefCallback, useEffect, useState } from 'react';

const useFocusOnAppearance: () => RefCallback<HTMLInputElement> = () => {
    const [searchBarRef, setSearchBarRef] = useState<HTMLInputElement | null>(null);
    useEffect(() => {
        if (searchBarRef) {
            searchBarRef.focus();
        }
    }, [searchBarRef]);
    return (node: HTMLInputElement) => {
        setSearchBarRef(node);
    };
};

export default useFocusOnAppearance;
