import { FC } from 'react';

import Column from 'bloko/blocks/column';

import { useSelector } from 'src/hooks/useSelector';

import BlogArticleList from 'src/pages/Blog/components/BlogArticleList';
import BlogCategoryLayout from 'src/pages/Blog/components/BlogCategoryLayout';
import BlogTopicsTagCloud from 'src/pages/Blog/components/BlogTopicsTagCloud';

const BlogSearchPage: FC = () => {
    const { resultTopics } = useSelector(({ blogPage }) => blogPage.searchData);
    return (
        <BlogCategoryLayout
            showTopicsFilter={false}
            renderAdditionalSideBlock={() => <BlogTopicsTagCloud topics={resultTopics} hhtmFromLabel="tag_cloud" />}
        >
            <Column xs="4" s="8" m="8" l="12">
                <BlogArticleList />
            </Column>
            <Column xs="0" s="0" m="1" l="1" />
        </BlogCategoryLayout>
    );
};

export default BlogSearchPage;
