import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';

import { ReadMoreArticle } from 'src/models/articlePage';
import { ArticleTypes } from 'src/models/articlePage/types';
import { BlogArticle } from 'src/models/blogPage';

const JOURNAL_ZP_DOMAIN = 'https://journal.modern.zarplata.ru';

function isJournalZPDomain(origin: string) {
    return origin.startsWith(JOURNAL_ZP_DOMAIN);
}

export const getArticleImage = (
    { images, preview }: BlogArticle | ReadMoreArticle,
    imageType = 'readmore'
): string | undefined => {
    return preview || images?.find(({ type }) => type === imageType)?.url;
};

export const useGetArticleHref = (
    { code, articleType }: BlogArticle | ReadMoreArticle,
    articleId?: string | undefined
): string => {
    const fromArticle = articleId ? `?from=article_${articleId}` : '';
    const origin = useSelectorNonNullable((state) => state.request.origin);
    const isJournal = isJournalZPDomain(origin);
    if (isJournal) {
        return `${origin}/${code}${fromArticle}`;
    }
    if (articleType === ArticleTypes.Insider) {
        return `/interview/${code}${fromArticle}`;
    }
    return `/article/${code}${fromArticle}`;
};
