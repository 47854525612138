import { useMemo } from 'react';

import { H3Promo } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ArticleTopics } from 'src/models/articlePage/types';
import { BlogDictionaryItem } from 'src/models/blogPage';

import BlogDesktopMenu from 'src/pages/Blog/components/Header/BlogDesktopMenu';
import BlogMobileMenu from 'src/pages/Blog/components/Header/BlogMobileMenu';

import styles from './blog-header.less';

const TrlKeys = {
    title: 'blog.main.title',
    menuMainItem: 'blog.menu.main',
    InsiderArchive: 'articles.topics.insider-archive',
    ItProjectsArchive: 'articles.topics.it-projects-archive',
    Search: 'blog.search.title',
};

const Header: TranslatedComponent = ({ trls }) => {
    const extraItemToMain: BlogDictionaryItem = useMemo(
        () => ({
            code: '_blog_index',
            name: trls[TrlKeys.menuMainItem],
        }),
        [trls]
    );
    const { rubrics, rubric, topic, searchData } = useSelector(({ blogPage }) => blogPage);
    const query = searchData?.query;
    const preparedRubrics = useMemo(() => {
        const orderedRubrics = rubrics.filter(({ code }) => code !== 'specs').sort((a, b) => a.priority - b.priority);
        return rubric || query ? [extraItemToMain, ...orderedRubrics] : orderedRubrics;
    }, [extraItemToMain, query, rubric, rubrics]);
    let title = trls[TrlKeys.title];

    if (rubric) {
        title = rubrics.find(({ code }) => code === rubric)?.name || trls[TrlKeys.title];
    }

    if (topic) {
        title =
            rubrics.find(({ code }) => code === rubric)?.topics?.find(({ code }) => code === topic)?.name ||
            trls[TrlKeys.title];
        if (topic === ArticleTopics.InsiderArchive) {
            title = trls[TrlKeys.InsiderArchive];
        }
        if (topic === ArticleTopics.ItProjectsArchive) {
            title = trls[TrlKeys.ItProjectsArchive];
        }
    }

    if (query) {
        title = format(trls[TrlKeys.Search], {
            '{0}': query,
        });
    }

    return (
        <div className={styles.header}>
            {!!preparedRubrics.length && (
                <>
                    <div className={styles.mobileMenu}>
                        <BlogMobileMenu menuItems={preparedRubrics} rubric={rubric} />
                    </div>
                    <VSpacing base={0} xs={6} />
                </>
            )}
            {rubric && !!preparedRubrics.length && (
                <div className={styles.desktopMenu}>
                    <BlogDesktopMenu menuItems={preparedRubrics} />
                    <VSpacing base={8} />
                </div>
            )}
            <H3Promo Element="h1">{title}</H3Promo>
            {!rubric && !!preparedRubrics.length && (
                <>
                    <VSpacing base={8} xs={0} />
                    <div className={styles.desktopMenu}>
                        <BlogDesktopMenu menuItems={preparedRubrics} />
                    </div>
                </>
            )}
        </div>
    );
};

export default translation(Header);
